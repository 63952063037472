import React from 'react';
import { StyledBannerButtonWithIcon } from './BannerButtonWithIcon.styles';
import { IBannerButtonWithIcon } from './BannerButtonWithIcon.types';
import Icon from '../../elements/Icon';
import Container from '../../elements/Container';
import { px2Rem } from '../../utils/px2Rem';
import LottieAnimation from '../LottieAnimation';

export const BannerButtonWithIcon = ({
  dataTestId,
  borderRadius,
  fontSize,
  lineHeight,
  backgroundColor,
  color,
  icon,
  linkText,
  onClick,
  children,
  animation,
  makeWholeBannerClickable,
}: IBannerButtonWithIcon) => {
  return (
    <StyledBannerButtonWithIcon
      data-testid={dataTestId}
      borderRadius={borderRadius}
      fontSize={fontSize}
      lineHeight={lineHeight}
      backgroundColor={backgroundColor}
      color={color}
      onClick={makeWholeBannerClickable ? onClick : undefined}
      makeWholeBannerClickable={makeWholeBannerClickable}
    >
      <Container padding={`0 ${px2Rem(96)} 0 0`} width="auto">
        {children}
      </Container>
      <button onClick={onClick}>{linkText}</button>
      {icon && (
        <Icon
          dataTestId={`${dataTestId}-icon`}
          name={icon}
          $height={120}
          $width={120}
          $styles={`position: absolute; bottom: ${px2Rem(8)}; right: 0;`}
        />
      )}
      {animation && (
        <LottieAnimation isLoop animationData={animation} className="animation" />
      )}
    </StyledBannerButtonWithIcon>
  );
};
