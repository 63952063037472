import React from 'react';
import { CheckoutPageWidgetProps } from './CheckoutPageWidget.types';
import { palette } from '../../colors';
import BulletList from '../../components/BulletList';
import TextWithIcon from '../../components/TextWithIcon';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Spacer from '../../elements/Spacer';
import { px2Rem } from '../../utils/px2Rem';

const CheckoutPageWidget: React.FC<CheckoutPageWidgetProps> =
  ({
    formattedMonthlyPrice,
    formattedInitialAmount,
    items,
    linkOnClick,
  }) => (
    <Container
      padding={`${px2Rem(16)} ${px2Rem(16)} ${px2Rem(16)} ${px2Rem(16)}`}
      backgroundColor={palette.charcoal[100]}
      maxWidth={px2Rem(760)}
      dataTestId="CheckoutPageWidget"
      widthInitial
    >
      <Copy bold fontSize={14}>
        Lease from {formattedMonthlyPrice}/mo. {formattedInitialAmount} upfront today
      </Copy>
      <Spacer height={8} />
      <BulletList items={items} />
      <TextWithIcon icon="Info" copy="Learn more" onClick={linkOnClick} />
    </Container>
  );

export default CheckoutPageWidget;
