import { ConsumerTypeMoneyField, consumerTypeMoneyField, ConsumerTypesEnum } from '@/types/consumerTypes';
import { prefixUrlPathByConsumerType } from '@/utils/urls.utils';
import constate from 'constate';

type UseConsumerTypeProps = {
  consumerType?: ConsumerTypesEnum;
};

type UseConsumerTypeReturnValues = {
  /**
   * The type of consumer, either `ConsumerTypesEnum.PERSONAL` or `ConsumerTypesEnum.BUSINESS`
   */
  consumerType: ConsumerTypesEnum;
  /**
   * Shortcut for if the consumer type is `ConsumerTypesEnum.PERSONAL`
   */
  consumerIsPersonal: boolean;
  /**
   * Shortcut for if the consumer type is `ConsumerTypesEnum.BUSINESS`
   */
  consumerIsBusiness: boolean;
  /**
   * A function which prefixes the pathname of a link with the correct prefix for that consumer type
   */
  consumerTypePrefixPath: (path: Parameters<typeof prefixUrlPathByConsumerType>[1]) => ReturnType<typeof prefixUrlPathByConsumerType>;
  /**
   * The `Money` field to get the price from within the `TaxableAmount` field
   */
  consumerMoneyField: ConsumerTypeMoneyField;
};

const useConsumerType = ({ consumerType = ConsumerTypesEnum.PERSONAL }: UseConsumerTypeProps): UseConsumerTypeReturnValues => {
  const consumerTypePrefixPath: UseConsumerTypeReturnValues['consumerTypePrefixPath'] = (path) => {
    return prefixUrlPathByConsumerType(consumerType, path);
  };

  return {
    consumerType,
    consumerIsPersonal: consumerType === ConsumerTypesEnum.PERSONAL,
    consumerIsBusiness: consumerType === ConsumerTypesEnum.BUSINESS,
    consumerTypePrefixPath,
    consumerMoneyField: consumerTypeMoneyField[consumerType],
  };
};

const [ConsumerTypeProvider, useConsumerTypeContext] = constate(useConsumerType);

export { ConsumerTypeProvider, useConsumerTypeContext };
