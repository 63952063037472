import React from 'react';
import { palette } from '../../colors';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Icon from '../../elements/Icon';
import NumberStepContainer from '../../elements/NumberStepContainer';
import Spacer from '../../elements/Spacer';
import TextLink from '../../elements/TextLink';
import { px2Rem } from '../../utils/px2Rem';
import { StyledHowItWorksCard } from './HowItWorksCard.styles';
import { IHowItWorksCard } from './HowItWorksCard.types';

export const HowItWorksCard = ({
  dataTestId,
  step,
  icon,
  title,
  copy,
  ctaText,
  ctaOnClick,
}: IHowItWorksCard) => {
  return (
    <StyledHowItWorksCard data-testid={dataTestId}>
      <Container
        border={palette.charcoal[200]}
        backgroundColor={palette.white}
        padding={`0 ${px2Rem(16)}`}
        width="initial"
      >
        <Spacer height={16} />
        <Container flexRow>
          <Container fitContent>
            <NumberStepContainer
              step={step}
              dataTestId="StepNumber"
            />
          </Container>
          <Container alignRight>
            <Icon name={icon} $width={72} />
          </Container>
        </Container>
        <Container>
          <Copy bold fontSize={16} color={palette.charcoal[500]}>
            {title}
          </Copy>
          <Spacer height={12} />
          <Copy fontSize={16}>{copy}</Copy>
          {ctaText && (
            <>
              <Spacer height={16} />
              <TextLink
                onClick={ctaOnClick}
                fontColor={palette.blue[500]}
                noUnderline
              >
                {ctaText}
              </TextLink>
            </>
          )}
        </Container>
        <Spacer height={24} />
      </Container>
    </StyledHowItWorksCard>
  );
};
