import React from 'react';
import { StyledGrid } from './Grid.styles';
import { IGrid } from './Grid.types';

export const Grid = ({
  dataTestId,
  children,
  columns,
  columnsMobile,
  columnsTablet,
  maxWidth,
  minColumnWidth,
  maxColumnWidth,
  rowGap,
  columnGap,
  gap,
  matchRowHeight,
}: IGrid) => {
  return (
    <StyledGrid
      data-testid={dataTestId}
      matchRowHeight={matchRowHeight}
      columns={columns}
      columnsMobile={columnsMobile}
      columnsTablet={columnsTablet}
      minColumnWidth={minColumnWidth}
      maxColumnWidth={maxColumnWidth}
      maxWidth={maxWidth}
      rowGap={rowGap}
      columnGap={columnGap}
      gap={gap}
    >
      {children}
    </StyledGrid>
  );
};
