import React from 'react';
import Copy from '../../elements/Copy';
import Container from '../../elements/Container';
import Icon from '../../elements/Icon';
import { StyledPricePoint } from './PricePoint.styles';
import { IPricePoint } from './PricePoint.types';
import { px2Rem } from '../../utils/px2Rem';

const copy = (formattedMonthlyPrice?: string): string => {
  if (formattedMonthlyPrice) {
    return `Lease from ${formattedMonthlyPrice}/mo`;
  }
  return 'Raylo Pay is not available for this product';
};
export const PricePoint = ({ dataTestId, formattedMonthlyPrice }: IPricePoint) => {
  return (
    <StyledPricePoint data-testid={dataTestId}>
      <Container flexRow maxWidth={px2Rem(760)}>
        <Container centerVertical>
          <Copy dataTestId="pricePointCopy" fontSize={16}>{copy(formattedMonthlyPrice)}</Copy>
        </Container>
        <Container alignRight fitContent>
          <Icon name="RpBadge" $width={56} />
        </Container>
      </Container>
    </StyledPricePoint>
  );
};
