import React from 'react';
import Copy from '../../elements/Copy';
import Icon from '../../elements/Icon';
import Container from '../../elements/Container';
import { StyledTextWithIcon, StyledTextLinkWrapper } from './TextWithIcon.styles';
import { ITextWithIcon } from './TextWithIcon.types';
import { palette } from '../../colors';
import TextLink from '../../elements/TextLink';

export const TextWithIcon = ({
  dataTestId,
  copy,
  icon,
  onClick,
  showIcon = true,
}: ITextWithIcon) => {
  return (
    <StyledTextWithIcon data-testid={dataTestId}>
      <Container flexRow>
        {showIcon && (
          <Container fitContent padding="0 4px 0 0">
            <Icon name={icon} dataTestId="icon" />
          </Container>
        )}
        <Container>
          <StyledTextLinkWrapper>
            <TextLink fontSize={14} onClick={onClick}>
              {copy}
            </TextLink>
          </StyledTextLinkWrapper>
        </Container>
      </Container>
    </StyledTextWithIcon>
  );
};
