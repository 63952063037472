import { PropsWithChildren } from 'react';
import { StyledShadow } from './styles';
import { ShadowProps } from './types';

export const Shadow = ({
  elevation,
  reverse,
  component,
  children,
  ...otherProps
}: PropsWithChildren<ShadowProps>) => {
  return (
    <StyledShadow
      as={component}
      $elevation={elevation}
      $reverse={reverse}
      {...otherProps}
    >
      {children}
    </StyledShadow>
  );
};
