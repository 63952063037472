import React from 'react';
import { StyledSquareNumber } from './SquareNumber.styles';
import { ISquareNumber } from './SquareNumber.types';

export const SquareNumber = ({
  dataTestId,
  number,
  background,
}: ISquareNumber) => {
  return (
    <StyledSquareNumber data-testid={dataTestId} background={background}>
      {number}
    </StyledSquareNumber>
  );
};
