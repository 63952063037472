import React from 'react';
import { ISectionMessageWithAnimation } from './SectionMessageWithAnimation.types';
import Container from '../../../elements/Container';
import { palette } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import SectionMessageBase from '../SectionMessageBase';
import LottieAnimation from '../../../components/LottieAnimation';

export const SectionMessageWithAnimation = ({
  dataTestId,
  content,
  animationLeftAlign,
  backgroundColor = palette.blue[100],
  borderColor = palette.blue[200],
  animationData,
}: ISectionMessageWithAnimation) => {
  const animationPadding = animationLeftAlign ? `0 ${px2Rem(8)} 0 0` : `0 0 0 ${px2Rem(8)}`;

  return (
    <SectionMessageBase
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      dataTestId={`${dataTestId}-base`}
      content={content}
      padding={`${px2Rem(8)} ${px2Rem(16)}`}
      alignCenter
    >
      <Container
        fitContent
        padding={animationPadding}
        order={animationLeftAlign ? '-1' : '1'}
        dataTestId={`${dataTestId}-animation`}
      >
        <LottieAnimation animationData={animationData} width={20} height={20} isLoop />
      </Container>
    </SectionMessageBase>
  );
};
