import React from 'react';
import { palette } from '../../../colors';
import Container from '../../../elements/Container';
import Copy from '../../../elements/Copy';
import Divider from '../Divider';
import { ISimpleFooter } from './SimpleFooter.types';

export const SimpleFooter = ({ dataTestId }: ISimpleFooter) => {
  return (
    <Container width="100%" noPadding dataTestId={dataTestId}>
      <Divider height={20} />
      <Copy fontSize={12} color={palette.charcoal[500]}>
        © Copyright of Raylo Group {new Date().getFullYear()}. All rights
        reserved.
      </Copy>
    </Container>
  );
};
