import React from 'react';
import {
  StyledRecommendationCard,
  StyledRecommendationCardContent,
  StyledRecommendationImage,
  StyledRecommendationCardText
} from './RecommendationCard.styles';
import Copy from '../../elements/Copy';
import { palette } from '../../colors';
import { IRecommendationCard } from './RecommendationCard.types';
import { PreApprovedBadgeContainer } from '../PreApprovedBadge';
import { px2Rem } from '../../utils/px2Rem';
import { formatDisplayPrice } from '../../utils/formatDisplayPrice';

export const RecommendationCard = ({ ImageComponent, category, productName, monthlyPrice, onClick }: IRecommendationCard) => {
  return (
    <PreApprovedBadgeContainer>
      <StyledRecommendationCard data-testid="recommendation-card" onClick={onClick}>
        <StyledRecommendationImage>
          <ImageComponent />
        </StyledRecommendationImage>
        <StyledRecommendationCardContent>
          <Copy fontSize={14} color={palette.blue[500]}>{category}</Copy>
          <StyledRecommendationCardText>
            <span>From</span>
            <span style={{ fontSize: px2Rem(18), fontWeight: 500, color: palette.charcoal[500], paddingLeft: px2Rem(6), lineHeight: px2Rem(20) }}>£{formatDisplayPrice(monthlyPrice)}</span>
            <span>/mo</span>
          </StyledRecommendationCardText>
        </StyledRecommendationCardContent>
        <Copy truncate fontSize={16}>{productName}</Copy>
      </StyledRecommendationCard>
    </PreApprovedBadgeContainer>
  );
};
