import Copy from '../../elements/Copy';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from '../../colors';
import { StyledIconButton, StyledViewAllButtonContainer } from './Carousel.styles';
import Icon from '../../elements/Icon';
import React from 'react';

export const CarouselViewAllButton = ({ centreContent, viewAllOnClick }: { centreContent: boolean; viewAllOnClick: () => void }) => {
  return (
    <StyledViewAllButtonContainer centreContent={centreContent}>
      <button
        style={{
          backgroundColor: palette.charcoal[500],
          display: 'flex',
          border: 'none',
          alignItems: 'center',
          gap: px2Rem(12),
          cursor: 'pointer',
        }}
        onClick={viewAllOnClick}
      >
        <Copy color={palette.white}>
          View all
        </Copy>
        <StyledIconButton>
          <Icon $height={14} name="ArrowRight" />
        </StyledIconButton>
      </button>
    </StyledViewAllButtonContainer>
  );
};
