// Generated with util/create-component.js
import React from 'react';
import { useMedia } from 'use-media';
import { palette } from '../../colors';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Icon from '../../elements/Icon';
import StyledSpan from '../../elements/StyledSpan';
import { ProductPageWidgetProps } from './ProductPageWidget.types';

const ProductPageWidget: React.FC<ProductPageWidgetProps> = ({
  formattedMonthlyPrice,
  formattedInitialAmount,
  linkOnClick,
}) => {
  const isXS = useMedia({ maxWidth: 392 });

  return (
    <Container
      flexRow
      padding="18px 16px"
      width="initial"
    >
      <Container fitContent centerVertical height="100%" padding="0 14px 0 0">
        <Icon name="RpBadge" $width={56} />
      </Container>
      <Container
        centerVertical
        height="100%"
        className="copy-container"
        noPadding
      >
        <Copy
          fontSize={14}
          color={palette.charcoal[500]}
          lineHeight={18.5}
          letterSpacing={-0.2}
        >
          Lease from{' '}
          <StyledSpan mediumFont>{formattedMonthlyPrice}/mo. {formattedInitialAmount} upfront today
            {!isXS ? <br /> : <>&nbsp;</>}
            <StyledSpan fontSize={14} color={palette.blue[500]} onClick={linkOnClick} regularFont underline>
              Learn more
            </StyledSpan>
          </StyledSpan>
        </Copy>
        <Copy fontSize={12} lineHeight={16} color={palette.charcoal[400]} letterSpacing={-0.2} styles={{ padding: '12px 0 0 0' }}>
          Select Raylo Pay at checkout.
        </Copy>
      </Container>
    </Container>
  );
};

export default ProductPageWidget;
