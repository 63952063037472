import React, { ReactElement } from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import { DotButton, useDotButton } from './CarouselDotButton';
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './CarouselArrowButtons';
import { CarouselViewAllButton } from './CarouselViewAllButton';
import useEmblaCarousel from 'embla-carousel-react';
import {
  StyledCarouselSection,
  StyledCarouselViewPort,
  StyledCarouselContainer,
  StyledCarouselSlide,
  StyleCarouselControls,
  StyledCarouselButtons,
  StyledCarouselDots,
  StyledCarouselDotsContainer
} from './Carousel.styles';

type PropType = {
  slides: ReactElement[];
  options?: EmblaOptionsType;
  viewAllOnClick?: () => void;
};

export const Carousel: React.FC<PropType> = (props) => {
  const { slides, options, viewAllOnClick } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);
  const [clickDirection, setClickDirection] = React.useState('');

  const {
    prevBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const onDirectionButtonClick = (direction: string) => {
    setClickDirection(direction);
    if (direction === 'prev') {
      onPrevButtonClick();
    } else if (direction === 'next') {
      onNextButtonClick();
    }
  };

  const allSlidesAreVisible = scrollSnaps.length === 1;

  return (
    <StyledCarouselSection data-testid="carousel-component">
      {viewAllOnClick && <CarouselViewAllButton centreContent={allSlidesAreVisible} viewAllOnClick={viewAllOnClick} />}
      {!allSlidesAreVisible && (
        <StyleCarouselControls>
          <StyledCarouselDotsContainer>
            <StyledCarouselDots>
              {scrollSnaps.map((_, index) => (
                <DotButton
                  clickDirection={clickDirection}
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => onDotButtonClick(index)}
                />
              ))}
            </StyledCarouselDots>
          </StyledCarouselDotsContainer>
          <StyledCarouselButtons>
            <PrevButton onClick={() => onDirectionButtonClick('prev')} disabled={prevBtnDisabled} />
            <NextButton onClick={() => onDirectionButtonClick('next')} />
          </StyledCarouselButtons>
        </StyleCarouselControls>
      )}
      <StyledCarouselViewPort ref={emblaRef}>
        <StyledCarouselContainer centreContent={allSlidesAreVisible}>
          {slides.map((slideContent, index) => (
            <StyledCarouselSlide key={index}>{slideContent}</StyledCarouselSlide>
          ))}
        </StyledCarouselContainer>
      </StyledCarouselViewPort>
    </StyledCarouselSection>
  );
};
