import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState
} from 'react';
import { EmblaCarouselType } from 'embla-carousel';
import { StyledCarouselButton } from './Carousel.styles';
import { Icon } from '../..';

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;

    if (!emblaApi.canScrollNext()) {
      emblaApi.scrollTo(0);
      return;
    }
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <StyledCarouselButton
      data-testid="carousel-arrow-button-prev"
      {...restProps}
    >
      {props.disabled ? <Icon name="ArrowLeftDisabled" /> : <Icon name="ArrowLeft" />}
      {children}
    </StyledCarouselButton>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <StyledCarouselButton
      data-testid="carousel-arrow-button-next"
      {...restProps}
    >
      <Icon name="ArrowRight" />
      {children}
    </StyledCarouselButton>
  );
};
