import { ConsumerTypesEnum, NavBarWrapper, type NavDataModel } from '@raylo-tech/raylopay-ui';
import { useState, useEffect } from 'react';
import { Link } from '../../elements/Link/Link';
import { useRouter } from 'next/router';
import { consumerTypePathBase } from '@/types/consumerTypes';
import { prefixUrlPathByConsumerType } from '@/utils/urls.utils';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useCookies } from 'react-cookie';

const GlobalNavBar = ({
  navData,
  hasAuthenticatedUser,
}: {
  navData: NavDataModel;
  hasAuthenticatedUser: boolean;
}) => {
  const [cookies] = useCookies();
  const { consumerType, consumerIsBusiness } = useConsumerTypeContext();
  /**
   * Reading the `hasLoggedInCustomer` value directly from the context can
   * cause a hydration issue between the server-generated markup and the
   * client-generated markup. We can conditionally show the
   * `ExistingCustomerNavBar` and `AccountBanner` by using a local state to
   * store the value of `hasLoggedInCustomer` and updating it when the context
   * value changes.
   *
   * Same for `localShowConsumerToggle`, which is only temporary and should be
   * removed once SMB goes live.
   */
  const [localHasLoggedInCustomer, setLocalHasLoggedInCustomer] = useState(false);
  const [localShowConsumerToggle, setLocalShowConsumerToggle] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setLocalHasLoggedInCustomer(hasAuthenticatedUser);
  }, [hasAuthenticatedUser]);

  useEffect(() => {
    const userHasBusinessCookie = !!cookies['raylo-business-preview'];
    setLocalShowConsumerToggle(userHasBusinessCookie);
  }, [cookies]);

  if (localHasLoggedInCustomer || router.asPath === '/recommendations') return null;

  const toggleUrl = (() => {
    if (consumerIsBusiness) {
      return router.asPath.replace(consumerTypePathBase[ConsumerTypesEnum.BUSINESS], consumerTypePathBase[ConsumerTypesEnum.PERSONAL]);
    }

    return prefixUrlPathByConsumerType(
      ConsumerTypesEnum.BUSINESS,
      router.asPath as Parameters<typeof prefixUrlPathByConsumerType>[1]
    );
  })();

  return (
    <NavBarWrapper
      accountHomeUrl={process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}
      productsUrl={consumerTypePathBase[consumerType]}
      navData={navData}
      LinkComponent={Link}
      consumerType={consumerType}
      toggleUrl={toggleUrl}
      showConsumerToggle={localShowConsumerToggle}
    />
  );
};

export default GlobalNavBar;
