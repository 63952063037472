import React from 'react';
import { StyledButtonWithIcon } from './ButtonWithIcon.styles';
import { IButtonWithIcon } from './ButtonWithIcon.types';
import Icon from '../../elements/Icon';
import Copy from '../../elements/Copy';

export const ButtonWithIcon = ({
  dataTestId,
  children,
  icon,
  borderRadius,
  fontSize,
  backgroundColor,
  onClick,
  lineHeight,
  bold,
}: IButtonWithIcon) => {
  return (
    <StyledButtonWithIcon
      data-testid={dataTestId}
      onClick={onClick}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      lineHeight={lineHeight}
    >
      <Copy bold={bold}>{children}</Copy>
      <Icon name={icon} $styles="padding-left: 12px;" />
    </StyledButtonWithIcon>
  );
};
