import { ConsumerTypesEnum, consumerTypeMoneyField, type ConsumerTypeMoneyField } from '@raylo-tech/raylopay-ui';

export const consumerTypePathBase = {
  [ConsumerTypesEnum.PERSONAL]: '' as const,
  [ConsumerTypesEnum.BUSINESS]: '/business' as const,
};

export const consumerTypeKeysList = Object.keys(consumerTypePathBase) as ConsumerTypesEnum[];

export { ConsumerTypesEnum, consumerTypeMoneyField, type ConsumerTypeMoneyField };
